<template>
	<div class="page">
		<gc-header header-data="secretKeyManage">
			<template #ops>
				<el-button type="primary" @click="showAddModel">新增密钥</el-button>
			</template>
		</gc-header>
		<div class="page-main">
			<div class="page-main-right">
				<gc-empty v-if="tableData.length === 0" />
				<div class="table-area" v-else>
					<gc-table
						class="table"
						:columns="columns"
						:table-data="tableData"
						:border="true"
						:show-page="false"
						:current-page="page.current"
						:total="page.total"
					></gc-table>
				</div>
			</div>
		</div>
		<add-key ref="addKeyModel" />
	</div>
</template>

<script>
import AddKey from './components/AddKey.vue'
export default {
	name: 'SecretKeyManage',
	components: { AddKey },
	props: {},
	data() {
		return {
			columns: [
				{
					key: 'companyName',
					name: '厂商',
				},
				{
					key: 'createTime',
					name: '创建时间',
				},
			],
			tableData: [
				{
					department: '体验',
					staffName: '拉拉',
					post: '分公司',
					phone: 18936566956,
				},
			],
			page: {
				size: 10,
				total: 100,
				current: 5,
			},
		}
	},
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		showAddModel() {
			this.$refs.addKeyModel.show()
		},
	},
}
</script>
<style lang="scss" scoped></style>
